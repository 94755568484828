<template>
  <div id="defaultId1" class="company_detail">
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false" v-if="ImporttableData?.length > 0">
      <div>
        <h2 class="operations-head flex_center_between_box">
          <span class="head-titel detail_title_20">News </span>
        </h2>
        <div class="table-biao" style="position: relative;">
          <el-input   autocomplete="off" class="input-str" v-model="SearchHscode" v-if="isshowSearchHscode" type="text"
            suffix-icon="el-icon-search" placeholder="Search for Title" @blur="changSeach()" />
          <el-table :data="ImporttableData" ref="table" empty-text="No Data" style="width: 100%" :key="Math.random()" class="table_height_30 table_font_size_14 table_contentHeight_40">
            <el-table-column prop="Date" label="Date" width="165" align="left">
              <template slot-scope="scope">
                <span class="table-content">{{ scope.row.date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="Title" label="Title" align="left" show-overflow-tooltip>
              <template slot="header">
                <span class="flex_tb_center">
                  <span v-if="!isshowSearchHscode" class="table_add_Text">Title</span>
                  <svg v-show="!isshowSearchHscode" t="1718683815723" class="icon ml5" @click="searchPersonByName()"
                    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7262" width="2.14rem"
                    height="2.14rem">
                    <path
                      d="M863.38 825.09L714.17 675.88c48.01-57.12 74.18-128.58 74.18-204.08 0-84.89-33.06-164.7-93.08-224.72C635.24 187.05 555.44 154 470.55 154c-84.89 0-164.7 33.06-224.72 93.08s-93.08 139.83-93.08 224.72 33.06 164.7 93.08 224.72 139.83 93.08 224.72 93.08c76.22 0 148.33-26.67 205.72-75.56l149.08 149.08a26.805 26.805 0 0 0 19.01 7.88c6.88 0 13.76-2.63 19.01-7.88a26.805 26.805 0 0 0 7.88-19.01c0-6.89-2.62-13.77-7.87-19.02zM206.53 471.8c0-145.58 118.44-264.02 264.02-264.02 145.58 0 264.02 118.44 264.02 264.02S616.13 735.82 470.55 735.82c-145.58 0-264.02-118.44-264.02-264.02z"
                      fill="#1290C9" p-id="7263"></path>
                  </svg>
                </span>
              </template>
              <template slot-scope="scope">
                <span class="" style="color: #1290C9;cursor: pointer;" @click="setid(scope.row)">{{ scope.row.titleEn }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="Source" width="450" label="Source" align="left" show-overflow-tooltip>
              <template slot="header">
                <span>
                  <el-select style="width: 400px" v-model="SelectHscode" placeholder="Select a Source Criteria"
                    v-show="isshowSearchHscodetwo">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <span v-show="!isshowSearchHscodetwo" class="table_add_Text">Source</span>
                </span>
              </template>
              <template slot-scope="scope">
                <span class="table-content">{{ scope.row.sourceEn }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="Impact" label="Impact" width="260" align="center">
              <template slot-scope="scope">
                <div class="flex_center">
                  <div v-if="scope.row.emotionLabel == '3'" class="impact_status red"><img
                      src="../../../../assets/img/tbl_ic2.png" /></div>
                  <div v-else class="impact_status green"><img src="../../../../assets/img/tbl_ic1.png" /></div>

                  <!-- </el-tooltip> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center; margin: 1.428rem" v-if="this.page.total > this.page.pageSize" class="show_AllHeight">
            <span class="contclass show_All" @click="ShowAll()">Show All</span>
          </div>
        </div>
      </div>
    </gui_card>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>

  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
  getNews,
  getfilterConditions,
  getPdfLink,
} from "@/api/companyApi";
import rectcard from "../../../../components/rectCard/index.vue";
import content_text from "../../../../components/text_content/index.vue";
import gui_card from "../../../../components/gui_card/gui_card.vue";
import tableVue from "../Performance/components/table.vue";
import ReLogin from "../../../../components/ReLogin.vue";
//importEnd

export default {
  name: "",

  props: {},

  components: {
    // gui_card start
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    tableVue,
    ReLogin,
  },
  computed: {},
  mounted() {
    this.SearchHscode=JSON.parse(localStorage.getItem('CompanyBase'))?.base?.companyName

    this.getNewsList()
    this.SourceList()

  },
  data() {
    return {
      reLoginVis: false,
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      keyNum: 0,
      SearchHscode: "",
      SelectHscode: '',
      isshowSearchHscode: false,
      isshowSearchHscodetwo: false,
      ImporttableData: [
        {
          Date: "02-06-24",
          Title:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
          Source: "dpa international Service in English",
          Impact: "",
        },
        {
          Date: "02-06-24",
          Title:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
          Source: "dpa international Service in English",
          Impact: "",
        },
        {
          Date: "02-06-24",
          Title:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
          Source: "dpa international Service in English",
          Impact: "",
        },
        {
          Date: "02-06-24",
          Title:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
          Source: "dpa international Service in English",
          Impact: "",
        },
        {
          Date: "02-06-24",
          Title:
            "Update Data protection group files complaint against OpenAl and ChatGPT",
          Source: "dpa international Service in English",
          Impact: "",
        },
      ],
      options: [{
        value: 'text1',
        label: 'text1'
      }, {
        value: 'text2',
        label: 'text2'
      }
      ],
      newDialog: false,
      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  methods: {
    setid(row) {
      if (row.link == undefined) {
        getPdfLink({ articleRef: row.id }).then(res1 => {
          // let res1 = JSON.parse(crypto.decrypt(res));

          if (res1.data.includes('http')) {
            let routeData = res1.data;
            window.open(routeData, '_blank');
          } else {
            let routeData = 'http://' + res1.data;
            window.open(routeData, '_blank');
          }
          // let routeData = res1.data;
          // window.open(routeData, '_blank');
        });
      }
      if (row.link.includes('http')) {

        let routeData = row.link;

        window.open(routeData, '_blank');
      } else {
        let routeData = 'http://' + row.link;
        window.open(routeData, '_blank');
      }
      // let routeData = row.url;
      // window.open(routeData, '_blank');
    },
    SourceList() {
      getfilterConditions(
        {
          title: encodeURIComponent(this.SearchHscode),
          countryCode: this.$route.query.SACode1,
        }
      ).then(res => {

      })
    },
    getNewsList() {
      getNews({
        id3a: this.$route.query.id3a,
        countryCode: this.$route.query.SACode1,
        page: this.page.pageNo,
        pageSize: this.page.pageSize,
        title: encodeURIComponent(this.SearchHscode),
        source: this.SelectHscode

      }).then(Res => {
        if (Res.code == 200) {
        
          this.ImporttableData = Res.data.rows
          this.page.total=Res.data.total
          this.$emit('UpdatamarkTotalList', [])

        } else {
          this.ImporttableData = []
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
      // getfilterConditions()
    },
    showSearchHscode() { },
    changSeach() {
      if(this.SearchHscode==''){
        this.SearchHscode=JSON.parse(localStorage.getItem('CompanyBase'))?.base?.companyName
      }
      getNews({
        id3a: '',
        countryCode: '',
        page: this.page.pageNo,
        pageSize: this.page.pageSize,
        title:this.SearchHscode,
        source: this.SelectHscode

      }).then(Res => {
        if (Res.code == 200) {
        
          this.ImporttableData = Res.data.rows
          this.page.total=Res.data.total
          // this.$emit('UpdatamarkTotalList', [])

        } else {
          this.ImporttableData = []
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
      this.isshowSearchHscode = false
        ;
    },
    searchPersonByName() {

      this.isshowSearchHscode = true;
    },
    ShowAll() {
      if (this.page.total > this.page.pageSize) {
                    this.page.pageSize = this.page.pageSize + 5;
                    this.getNewsList()
                }
    
    },
  },

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.table-biao {
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  margin-top: 1.4285714285714286rem;
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  /* padding-left: 1.4285714285714286rem !important; */
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: 'DMSans Bold';
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

@media screen and (max-width: 1441px) {
  .contclass {
  font-size: 14px;
 
}
}
.uUIRzUi {
  padding-bottom: 0rem;
}

.table_add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_add_Text {
  font-family: 'DMSans Bold';
  text-align: left;
}

.table-content {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  line-height: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.impact_status {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  text-align: center;
  border-radius: 50%;
}

.impact_status img {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  margin-top: 0.5rem;
}

.impact_status.green {
  background: #55b419;
}

.impact_status.hui {
  background: #ccc;
}

.impact_status.blue {
  background: #1290c9;
}

.impact_status.red {
  background: #ff7600;
}

.input-str {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 13.214285714285714rem;
  z-index: 99;
  text-align: 7.142857142857143rem;
  width: 26%;
}

.input-str /deep/ .el-input__inner {
  margin-top: -0.14285714285714285rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142rem solid #8497AB;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem 0;
}
</style>
